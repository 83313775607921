.partners-section {
    padding: 80px 0;
  }
  
  .section-title {
    text-align: center;
    font-size: 28px;
    font-weight: bold;
    margin-bottom: 40px;
  }
  
  .partner-logo {
    display: block;
    width: 100%;
    max-width: 200px;
    height: auto;
    margin-bottom: 30px;
  }
  
  @media (max-width: 767px) {
    .section-title {
      font-size: 24px;
    }
  }
  