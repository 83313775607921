.contact-page {
  padding-top: 150px; /* Adjust the value to add space between the navbar and the content */
  text-align: center;
  padding: 40px;
}

  
  .map-container {
    height: 800px; 
  }
  