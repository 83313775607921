.preloader {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #0000;
    z-index: 9999;
    display: flex;
    align-items: center;
    justify-content: center;
}
.preloader img {
    width: 100px;
    height: 100px;
} 