.container {
    max-width: 100%;
    padding: 0 15px;
    margin: 0 auto;
    padding-top: 150px;
  }
  
  h2 {
    text-align: center;
    margin-bottom: 30px;
  }
  
  .card {
    border: none;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease;
  }
  
  .card:hover {
    transform: translateY(-5px);
  }
  
  .card-img-top {
    width: 100%;
    height: auto;
  }
  
  .card-body {
    padding: 20px;
  }
  
  .card-title {
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 10px;
  }
  
  .card-text {
    font-size: 14px;
    margin-bottom: 10px;
  }
  
  @media (min-width: 576px) {
    .card-columns {
      column-count: 2;
    }
  }
  
  @media (min-width: 768px) {
    .card-columns {
      column-count: 3;
    }
  }
  
  @media (min-width: 992px) {
    .card-columns {
      column-count: 4;
    }
  }
  
  @media (min-width: 1200px) {
    .card-columns {
      column-count: 5;
    }
  }
  