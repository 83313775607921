.product-section {
    padding: 40px;
    padding-top: 150px;
  }
  
  .product-title {
    text-align: center;
    font-size: 24px;
    margin-top: 20px;
  }
  
  .row {
    display: flex;
    flex-wrap: wrap;
    margin-right: -15px;
    margin-left: -15px;
  }
  
  .col-md-6 {
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    margin-bottom: 30px;
  }
  
  @media (min-width: 768px) {
    .col-md-6 {
      width: 50%;
    }
  }
  
  .product-card {
    background-color: #f8f8f8;
    border-radius: 8px;
    padding: 20px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease-in-out;
  }
  
  .product-card:hover {
    transform: translateY(-5px);
  }
  
  .product-card-image {
    width: 100%;
    height: auto;
    border-radius: 8px;
    margin-bottom: 10px;
  }
  
  .product-card-title {
    display: flex;
    align-items: center;
    font-size: 18px;
    margin-bottom: 0;
  }
  
  .product-card-title svg {
    margin-right: 10px;
  }
  
  .product-card-content {
    text-align: center;
  }
  