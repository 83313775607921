.navbar-nav .nav-link {
  position: relative;
  color: black;
  transition: color 0.3s;
}

.navbar-nav .nav-link::after {
  content: "";
  position: absolute;
  left: 0;
  bottom: -2px;
  width: 100%;
  height: 2px;
  background-color: red;
  visibility: hidden;
  transform: scaleX(0);
  transition: all 0.3s ease-in-out;
}

.navbar-nav .nav-link:hover,
.navbar-nav .nav-link.active,
.navbar-nav .nav-link:focus {
  color: red !important;
}

.navbar-nav .nav-link:hover::after,
.navbar-nav .nav-link.active::after,
.navbar-nav .nav-link:focus::after {
  visibility: visible;
  transform: scaleX(1);
}

.dropdown-item:hover,
.dropdown-item:focus {
  color: red !important;
  background-color: transparent;
}

.dropdown-item.active {
  color: red !important;
  background-color: transparent;
}

@media (max-width: 768px) {
  .navbar-nav .nav-link {
    color: black !important;
  }

  .navbar-nav .nav-link::after {
    display: none;
  }
}
