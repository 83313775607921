.training-section {
    padding: 80px 0;
  }
  
  .training-title {
    text-align: center;
    font-size: 32px;
    font-weight: bold;
    margin-bottom: 40px;
  }
  
  .training-member {
    position: relative;
    overflow: hidden;
    height: 300px;
    margin-bottom: 20px;
  }
  
  .training-image {
    width: 100%;
    height: auto;
    transition: transform 0.3s;
  }
  
  .training-image:hover {
    transform: scale(1.1);
  }
  
  .overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.6);
    opacity: 0;
    transition: opacity 0.3s;
  }
  
  .training-member:hover .overlay {
    opacity: 1;
  }
  
  .training-paragraph {
    text-align: center;
    font-size: 18px;
    margin-top: 40px;
  }
  
  @media (max-width: 768px) {
    .training-title {
      font-size: 24px;
    }
  
    .training-member {
      height: 200px;
    }
  
    .training-paragraph {
      font-size: 16px;
    }
  }
  