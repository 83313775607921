.team-section {
  padding: 80px 0;
}

.team-title {
  text-align: center;
  font-size: 32px;
  font-weight: bold;
  margin-bottom: 40px;
}

.team-member {
  position: relative;
  overflow: hidden;
  height: 300px;
  margin-bottom: 20px;
}

.team-image {
  width: 100%;
  height: auto;
  transition: transform 0.3s;
  max-height: 400px;
}

.team-image:hover {
  transform: scale(1.1);
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  opacity: 0;
  transition: opacity 0.3s;
}

.team-member:hover .overlay {
  opacity: 1;
}

.team-paragraph {
  text-align: center;
  font-size: 18px;
  margin-top: 40px;
}

@media (max-width: 768px) {
  .team-title {
    font-size: 24px;
  }

  .team-member {
    height: 200px;
  }

  .team-paragraph {
    font-size: 16px;
  }
  
  .team-section {
    padding: 60px 0;
  }
}
