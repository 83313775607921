.service-section {
  padding: 40px;
  padding-top: 200px;
}

.service-title {
  text-align: center;
  font-size: 24px;
  margin-top: 20px;
}

.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
}

.col-md-6 {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-bottom: 30px;
}

@media (min-width: 768px) {
  .col-md-6 {
    width: 50%;
  }
}

.service-card {
  background-color: #f8f8f8;
  border-radius: 8px;
  padding: 20px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease-in-out;
  text-align: center;
}

.service-card:hover {
  transform: translateY(-5px);
}

.service-card-icon {
  width: 60px;
  height: auto;
  margin-bottom: 10px;
}

.service-card-title {
  font-size: 18px;
  margin-bottom: 0;
}
